<template>
  <patients />
</template>

<script>
import Patients from "@/components/patients/Patients.vue";

export default {
  name: "patients-view",
  components: {
    patients: Patients
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
