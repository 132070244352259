<template>
  <span>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="3">
          <h2 class="font-weight-light">Lista de Consultantes</h2>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-end justify-end">
          <v-btn
            @click="changeFilter"
            class="mx-2"
            x-small
            fab
            dark
            color="secondary"
          >
            <v-icon dark>{{
              activeFilter ? "mdi-close" : "mdi-filter"
            }}</v-icon>
          </v-btn>
          <v-btn
            @click="createPatient"
            class="mx-2"
            x-small
            fab
            dark
            color="primary"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card
        v-if="activeFilter"
        elevation="4"
        class="px-5 card-border-primary"
      >
        <v-row justify="center">
          <v-col cols="12" sm="3">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter.firstName"
              label="Primer Nombre"
              maxlength="100"
              counter
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter.middleName"
              label="Segundo Nombre"
              maxlength="100"
              counter
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter.lastName"
              label="Primer Apellido"
              maxlength="100"
              counter
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter.secondLastName"
              label="Segundo Apellido"
              maxlength="100"
              counter
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter.email"
              label="Correo electrónico"
              maxlength="100"
              counter
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" align="start">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDate"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  clearable
                  hide-details
                  maxlength="100"
                  v-on="on"
                  label="Fecha de nacimiento"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="date"
                no-title
                :max="maxDate()"
                @input="menu = false"
                color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="3">
            <v-select
              prepend-inner-icon="mdi-format-list-text"
              v-model="filter.gender"
              label="Género"
              :items="genderOptions"
              clearable
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              prepend-inner-icon="mdi-format-list-text"
              v-model="filter.civilStatus"
              label="Estado civil"
              :items="civilStatusOptions"
              clearable
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              prepend-inner-icon="mdi-format-list-text"
              v-model="filter.children"
              label="Hijos"
              :items="childrenOptions"
              clearable
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              prepend-inner-icon="mdi-format-list-text"
              v-model="filter.childPosition"
              label="Posición de hijo"
              :items="childPositionOptions"
              clearable
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn outlined color="primary" @click="loadFilteredPatients"
              >Buscar</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <br />
      <v-row justify="center">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="patients"
            :loading="loading"
            class="elevation-1 px-0"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: itemsPerPage,
              itemsPerPageText: 'Cantidad de consultantes por página'
            }"
            :items-per-page="filter.limit"
            :server-items-length="totalPatients"
            :loading-text="loadingText"
            :no-data-text="noDataText"
          >
            <template v-slot:[`item.details`]="{ item }">
              <v-btn color="gray" icon @click="seeDetails(item.id)" x-small>
                <v-icon dark>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <patient-modal
      v-if="showPatientModal"
      :showModal="showPatientModal"
      :titleToShow="patientModalTitle"
      @closeModal="openPatientModal"
      @loadPatients="loadPatientsWithNoFilters"
    />
  </span>
</template>

<script>
import PatientModal from "./PatientModal";
import Repository from "@/services/repositories/repositoryFactory";
const PatientRepository = Repository.get("patient");
export default {
  name: "patients",
  components: {
    "patient-modal": PatientModal
  },
  data() {
    return {
      showPatientModal: false,
      patientModalTitle: "",
      activeFilter: false,
      loading: false,
      headers: [
        {
          text: "Nombre Completo",
          value: "fullName",
          align: "center",
          justify: "center"
        },
        {
          text: "Apellido Completo",
          value: "fullLastName",
          align: "center",
          justify: "center"
        },
        {
          text: "Correo Electrónico",
          value: "email",
          align: "center",
          justify: "center"
        },
        {
          text: "Nacimiento",
          value: "birthdate",
          align: "center",
          justify: "center"
        },
        {
          text: "Lugar de nacimiento",
          value: "placeOfBirth",
          align: "center",
          justify: "center"
        },
        {
          text: "Teléfono",
          value: "phone",
          align: "center",
          justify: "center"
        },
        { text: "Género", value: "gender", align: "center", justify: "center" },
        {
          text: "Estado civil",
          value: "civilStatus",
          align: "center",
          justify: "center"
        },
        {
          text: "Hijos",
          value: "children",
          align: "center",
          justify: "center"
        },
        {
          text: "Posición de hijo",
          value: "childPosition",
          align: "center",
          justify: "center"
        },
        {
          text: "Ver",
          value: "details",
          align: "center",
          justify: "center",
          sortable: false
        }
      ],
      patients: [],
      reseting: false,
      options: {},
      totalPatients: 0,
      itemsPerPage: [5, 10, 15],
      loadingText: "Obteniendo los consultantes...",
      noDataText: "No hay consultantes registrados",
      filter: {
        firstName: "",
        middleName: "",
        lastName: "",
        secondLastName: "",
        email: "",
        gender: "",
        civilStatus: "",
        children: "",
        childPosition: "",
        birthdate: "",
        limit: 10,
        start: 0
      },
      menu: false,
      date: "",
      formattedDate: "",

      genderOptions: ["Hombre", "Mujer", "Otro"],
      civilStatusOptions: [
        "Solter@",
        "Casad@",
        "Unión libre / Concubinato",
        "Divorciad@",
        "Viud@",
        "Otro"
      ],
      childrenOptions: ["Sí", "No", "En gestación"],
      childPositionOptions: [
        "Hij@ únic@",
        "Primer@",
        "Segund@",
        "Tercer@",
        "Cuart@",
        "Quint@",
        "Otro"
      ]
    };
  },
  methods: {
    seeDetails(patientId) {
      this.$router.push({ path: `/app/admin/patients/${patientId}` });
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openPatientModal(visibility) {
      this.showPatientModal = visibility;
    },
    createPatient() {
      this.patientModalTitle = `Crear nuevo consultante`;
      this.openPatientModal(true);
    },
    maxDate() {
      let date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    changeFilter() {
      if (!this.activeFilter) {
        this.activeFilter = true;
      } else {
        this.loadPatientsWithNoFilters();
      }
    },
    async loadPatientsWithNoFilters() {
      this.activeFilter = false;
      this.reseting = true;
      this.resetFilter();
      await this.loadPatients();
      this.reseting = false;
    },
    filterFields() {
      this.filter.firstName = this.filter.firstName
        ? this.filter.firstName.replace(/&/g, "")
        : this.filter.firstName;
      this.filter.middleName = this.filter.middleName
        ? this.filter.middleName.replace(/&/g, "")
        : this.filter.middleName;
      this.filter.lastName = this.filter.lastName
        ? this.filter.lastName.replace(/&/g, "")
        : this.filter.lastName;
      this.filter.secondLastName = this.filter.secondLastName
        ? this.filter.secondLastName.replace(/&/g, "")
        : this.filter.secondLastName;
      this.filter.email = this.filter.email
        ? this.filter.email.replace(/&/g, "")
        : this.filter.email;
      this.filter.gender = this.filter.gender
        ? this.filter.gender.replace(/&/g, "")
        : this.filter.gender;
      this.filter.civilStatus = this.filter.civilStatus
        ? this.filter.civilStatus.replace(/&/g, "")
        : this.filter.civilStatus;
      this.filter.children = this.filter.children
        ? this.filter.children.replace(/&/g, "")
        : this.filter.children;
      this.filter.childPosition = this.filter.childPosition
        ? this.filter.childPosition.replace(/&/g, "")
        : this.filter.childPosition;
      this.filter.start = 0;
      this.options.page = 1;
    },
    resetFilter() {
      this.filter.firstName = "";
      this.filter.middleName = "";
      this.filter.lastName = "";
      this.filter.secondLastName = "";
      this.filter.email = "";
      this.filter.gender = "";
      this.filter.civilStatus = "";
      this.filter.children = "";
      this.filter.childPosition = "";
      this.filter.birthdate = "";
      this.filter.limit = 10;
      this.filter.start = 0;
      this.options.page = this.filter.start + 1;
      this.options.itemsPerPage = this.filter.limit;
      this.date = "";
    },
    async loadFilteredPatients() {
      this.reseting = true;
      await this.filterFields();
      this.reseting = false;
      await this.loadPatients();
      this.noDataText =
        "No hay consultantes registrados con el criterio de búsqueda";
    },
    async loadPatients() {
      this.loading = true;
      this.patients = [];
      this.noDataText = "No hay consultantes registrados";

      let data = await PatientRepository.getAllPatients(this.filter);
      if (data?.length) {
        this.totalPatients = data[1];
        if (data[0]?.length) {
          for await (let patient of data[0]) {
            let patientToAdd = {
              ...patient,
              fullName:
                patient.firstName +
                (patient.middleName ? ` ${patient.middleName}` : ""),
              fullLastName:
                patient.lastName +
                (patient.secondLastName ? ` ${patient.secondLastName}` : "")
            };
            this.patients.push(patientToAdd);
          }
        }
      }
      this.loading = false;
    }
  },
  watch: {
    formattedDate(newValue) {
      if (!newValue) {
        this.date = "";
      }
      this.filter.birthdate = newValue;
    },
    menu() {
      setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    date(newValue) {
      if (newValue) {
        this.formattedDate = this.formatDate(newValue);
      }
    },
    options: {
      handler(newValue) {
        if (!this.reseting) {
          const { page, itemsPerPage } = newValue;
          this.filter.limit = itemsPerPage;
          this.filter.start = (page - 1) * itemsPerPage;
          this.loadPatients();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.card-border-primary {
  border: solid 3px var(--v-primary-base);
}
</style>
